import { useInView } from "react-intersection-observer";
import { useGetCaregiverCommunitiesByAuthorAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { useState } from "react";
import { useModal } from "../../../hooks/useModal";
import { CaregiverCommunity } from "../../../lib/apollo/graphql/generated";

export const useCommunityBox = (caregiverId: string) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isModalOpen, { open, close }] = useModal();
  const [post, setPost] = useState<null | CaregiverCommunity>(null);

  const { data, loading, fetchMore } =
    useGetCaregiverCommunitiesByAuthorAsAdminQuery({
      variables: {
        caregiverId,
        first: 20,
      },
    });

  const pageInfo = data?.getCaregiverCommunitiesByAuthorAsAdmin.pageInfo;
  const posts = data?.getCaregiverCommunitiesByAuthorAsAdmin.edges;

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getCaregiverCommunitiesByAuthorAsAdmin.pageInfo;

    if (inView && !isFetchingMore && pageInfo?.hasNextPage) {
      setIsFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: pageInfo?.endCursor,
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  const togglePostModal = (post?: CaregiverCommunity | null) => {
    if (isModalOpen) {
      setPost(null);
      close();
    } else if (post) {
      setPost(post);
      open();
    }
  };

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      isModalOpen,
      posts,
      pageInfo,
      loading,
      isFetchingMore,
      post,
    },
    operations: {
      togglePostModal,
    },
  };
};
