import styled from "styled-components";
import { formatPhoneNumber } from "../../../utilities/format";
import { Dimensions, UserType } from "global";
import {
  Caregiver,
  Guardian,
  Receiver,
} from "../../../lib/apollo/graphql/generated";
import UserAvatar from "../../common/components/UserAvatar";
import { USERTYPE_TO_KOREAN } from "../../users/components/AddUserButton.hook";
import BaseBox from "./BaseBox";
import BaseCard from "./BaseCard";

interface RelatedUsersBoxProps extends Dimensions {
  type: UserType;
  users?: (Partial<Caregiver> | Partial<Receiver> | Partial<Guardian>)[];
  onUserClick?: (id: string) => void;
}

export default function RelatedUsersBox({
  type,
  users,
  width = "100%",
  height = "100%",
  onUserClick = () => null,
}: RelatedUsersBoxProps) {
  return (
    <BaseBox
      width={width}
      height={height}
      isEmpty={!users?.length}
      title={`연결된 ${USERTYPE_TO_KOREAN[type]}`}
      icon={
        <UserCount>
          <i className="bi bi-person" /> {users?.length || 0}명
        </UserCount>
      }
    >
      {users?.map((user) => {
        const isDeletdUser = !!user.deletedAt;

        return (
          <BaseCard onClick={() => onUserClick(user?.id!)}>
            <UserAvatar
              size={70}
              alt="연관된 유저 사진"
              src={"avatar" in user ? user?.avatar?.uri : undefined}
            />
            <InfoWrapper>
              <Name>
                {user?.name} {USERTYPE_TO_KOREAN[type]}
              </Name>
              <Phone>
                {isDeletdUser ? "(탈퇴 회원)" : formatPhoneNumber(user?.phone)}
              </Phone>
            </InfoWrapper>
          </BaseCard>
        );
      })}
    </BaseBox>
  );
}

const Name = styled.p(() => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 16,
}));

const Phone = styled.p(() => ({
  margin: 0,
  fontSize: 14,
}));

const InfoWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const UserCount = styled.span(() => ({
  fontSize: 14,
  fontWeight: "normal",
}));
