import styled from "styled-components";

interface TableColumnProps {
  children: React.ReactNode;
  width?: React.CSSProperties["width"];
  fallback?: React.ReactNode;
}

interface ColumnProps {
  $width: React.CSSProperties["width"];
}

export default function TableColumn({ children, width = "100%", fallback = "-" }: TableColumnProps) {
  return <Column $width={width}>{children ? children : fallback}</Column>;
}

const Column = styled.div<ColumnProps>(({ $width }) => ({
  width: $width,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
