import { UserType } from "global";
import { useModal } from "../../../hooks/useModal";
import DeleteUserModal from "./DeleteUserModal";
import BasicButton from "../../common/components/BasicButton";

interface DeleteUserButtonProps {
  type: UserType;
  userId: string;
  isDisabled: boolean;
}

export default function DeleteUserButton({
  type,
  userId,
  isDisabled,
}: DeleteUserButtonProps) {
  const [isUpdateModalOpen, { open, close }] = useModal();

  return (
    <>
      <DeleteUserModal
        isOpen={isUpdateModalOpen}
        onClose={close}
        type={type}
        userId={userId}
      />
      <BasicButton
        width="100%"
        text="회원 삭제"
        fontSize="16px"
        onClick={open}
        disabled={isDisabled}
        backgroundColor="red"
        hoverBackgroundColor="lightRed"
      />
    </>
  );
}
