import useReceiverList from "./ReceiverList.hook";
import PageWrapper from "../../common/components/PageWrapper";
import ListPageTopSection from "../../common/components/ListPageTopSection";
import GenderSelector from "../components/GenderSelector";
import SearchBar from "../../common/components/SearchBar";
import TableHeader from "../../common/components/TableHeader";
import Table from "../../common/components/Table";
import TableRow from "../../common/components/TableRow";
import { Gender } from "../../../lib/apollo/graphql/generated";
import TableColumn from "../../common/components/TableColumn";
import { formatPhoneNumber } from "../../../utilities/format";
import dayjs from "dayjs";
import ColoredLabel from "../../common/components/ColoredLabel";
import AddUserButton from "../components/AddUserButton";

export default function ReceiverList() {
  const {
    models: { pageInfo, data, filters, loading, isSearched, isFetchingMore },
    operations: { onRouteToUserDetailPage, onSearch, onCancelSearch, onFilterChange, onViewChange },
  } = useReceiverList();

  return (
    <PageWrapper>
      <ListPageTopSection>
        <GenderSelector gender={filters.gender} onChange={gender => onFilterChange("gender", gender)} />
        <SearchBar
          value={filters.query}
          placeholder="수급자 이름 혹은 전화번호"
          onChange={query => {
            onFilterChange("query", query);
          }}
          onSearch={onSearch}
          onCancel={isSearched ? onCancelSearch : undefined}
        />
        <AddUserButton type="receivers" />
      </ListPageTopSection>
      <TableHeader
        tabs={[
          { label: "이름" },
          { label: "성별" },
          { label: "전화번호" },
          { label: "생년월일" },
          { label: "생성일" },
          { label: "등록증 사진 여부" },
          { label: "수급자 번호" },
          { label: "삭제일" },
        ]}
      />
      <Table
        isLoading={loading}
        isEmpty={!data?.length}
        emptyMessage="조건에 맞는 수급자가 없습니다."
        infiniteScroll={{
          hasNextPage: pageInfo?.hasNextPage || false,
          isFetchingMore,
          onViewChange,
        }}
      >
        {data?.map(receiver => {
          const isDeleted = !!receiver?.deletedAt;
          const hasRegistrationImage = !!receiver?.registrationMedia?.uri;

          return (
            <TableRow onClick={() => onRouteToUserDetailPage(receiver?.id)}>
              <TableColumn>{receiver?.name}</TableColumn>
              <TableColumn>{receiver?.gender === Gender.Male ? "남" : "여"}</TableColumn>
              <TableColumn>{formatPhoneNumber(receiver?.phone)}</TableColumn>
              <TableColumn>{dayjs(receiver?.dateOfBirth).format("YYYY년 MM월 DD일")}</TableColumn>
              <TableColumn>{dayjs(receiver?.createdAt).format("YY.MM.DD HH:MM")}</TableColumn>
              <TableColumn>
                <ColoredLabel
                  backgroundColor={hasRegistrationImage ? "green" : "red"}
                  text={hasRegistrationImage ? "있음" : "없음"}
                />
              </TableColumn>
              <TableColumn>{receiver?.registrationNumber}</TableColumn>
              <TableColumn>{isDeleted && dayjs(receiver?.deletedAt).format("YY.MM.DD HH:MM")}</TableColumn>
            </TableRow>
          );
        })}
      </Table>
    </PageWrapper>
  );
}
