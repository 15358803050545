import styled from "styled-components";
import Loader from "../../common/components/Loader";
import BaseBox from "./BaseBox";
import { useCommunityBox } from "./CommunityBox.hook";
import BaseCard from "./BaseCard";
import { beautifyDate } from "../../../utilities/format";
import { CaregiverCommunityType } from "../../../lib/apollo/graphql/generated";
import UserAvatar from "../../common/components/UserAvatar";
import FetchMore from "../../common/components/FetchMore";
import CommunityModal from "./CommunityModal";

interface CommunityBoxProps {
  caregiverId: string;
}

export default function CommunityBox({ caregiverId }: CommunityBoxProps) {
  const {
    refs: { fetchMoreRef },
    models: { post, isModalOpen, posts, pageInfo, loading, isFetchingMore },
    operations: { togglePostModal },
  } = useCommunityBox(caregiverId);

  return (
    <BaseBox title="작성한 게시물" isLoading={loading} isEmpty={!posts?.length}>
      {isModalOpen && (
        <CommunityModal
          isOpen={isModalOpen}
          onClose={() => togglePostModal()}
          post={post}
        />
      )}
      {posts?.map((post) => {
        const postedAt = beautifyDate(post?.createdAt, true);
        const preview = post?.body.slice(0, 50) + "...";
        const author = post?.author;
        const isDeleted = !!post?.deletedAt;
        const type =
          post?.type === CaregiverCommunityType.General
            ? "일상주제"
            : "대나무숲";

        return (
          <BaseCard onClick={() => togglePostModal(post)}>
            <Wrapper>
              <Author>
                <UserAvatar src={author?.avatar?.uri} size={50} />
                <AuthorInfoWrapper>
                  <Name>
                    {author?.name} ({type} - 답글 {post?.replyCount}개)
                  </Name>
                  {isDeleted ? (
                    <span>삭제 ({beautifyDate(post?.deletedAt, true)})</span>
                  ) : (
                    <span>{postedAt}</span>
                  )}
                </AuthorInfoWrapper>
              </Author>
              <Body>{preview}</Body>
            </Wrapper>
          </BaseCard>
        );
      })}
      {isFetchingMore && <Loader />}
      {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
    </BaseBox>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: theme.spacing.small,
}));

const Author = styled.div(() => ({
  display: "flex",
  gap: 10,
  alignItems: "center",
}));

const AuthorInfoWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  fontSize: 14,
}));

const Name = styled.p(() => ({
  margin: 0,
  fontWeight: "bold",
}));

const Body = styled.p(() => ({
  margin: 0,
}));
