import styled from "styled-components";
import PageWrapper from "../../common/components/PageWrapper";
import ChatStats from "../components/ChatStats";
import UserStats from "../components/UserStats";
import CommunityStats from "../components/CommunityStats";

function MainContainer() {
  return (
    <PageWrapper isColumnFlex={false}>
      <ChatStats />
      <RightSide>
        <UserStats />
        <CommunityStats />
      </RightSide>
    </PageWrapper>
  );
}
const RightSide = styled.div(() => ({
  width: "150%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export default MainContainer;
