import styled from "styled-components";
import { useUpdateUserModal } from "./UpdateUserModal.hook";
import Modal from "../../common/components/Modal";
import { UserType } from "global";
import LeftLabel from "../../common/components/LeftLabel";
import { lazy, Suspense } from "react";
import Loader from "../../common/components/Loader";

interface UpdateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  type?: UserType | null;
  userId?: string;
}

export interface FormProps {
  onClose: () => void;
  userId?: string;
}

const LAZY_FORMS: {
  [key in UserType]: React.LazyExoticComponent<React.ComponentType<FormProps>>;
} = {
  caregivers: lazy(() => import("./CaregiverForm")),
  guardians: lazy(() => import("./GuardianForm")),
  receivers: lazy(() => import("./ReceiverForm")),
};

export default function UpdateUserModal({
  onClose,
  isOpen,
  type = null,
  userId,
}: UpdateUserModalProps) {
  const {
    models: { userType, modalTitle, isUpdating },
    operations: { onToggleType },
  } = useUpdateUserModal(type, userId);

  const LazyFormElement = userType ? LAZY_FORMS[userType] : null;

  return (
    <Modal open={isOpen} onClose={onClose} title={modalTitle} height="800px">
      <Wrapper>
        <LeftLabel label="회원 종류">
          <UserTypeSelect onChange={onToggleType} disabled={isUpdating}>
            <option value="" selected={!userType}>
              회원 종류
            </option>
            <option value="caregivers" selected={userType === "caregivers"}>
              요양보호사
            </option>
            <option value="guardians" selected={userType === "guardians"}>
              보호자
            </option>
            <option value="receivers" selected={userType === "receivers"}>
              수급자
            </option>
          </UserTypeSelect>
        </LeftLabel>
        <Suspense fallback={<Loader />}>
          {LazyFormElement ? (
            <LazyFormElement onClose={onClose} userId={userId} />
          ) : (
            <></>
          )}
        </Suspense>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(() => ({
  width: "100%",
  height: "100%",
}));

const UserTypeSelect = styled.select(() => ({
  padding: 5,
  borderRadius: 5,
}));
