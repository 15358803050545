import styled from "styled-components";
import { CaregiverCommunity } from "../../../lib/apollo/graphql/generated";
import Modal from "../../common/components/Modal";
import UserAvatar from "../../common/components/UserAvatar";
import { beautifyDate } from "../../../utilities/format";
import BasicButton from "../../common/components/BasicButton";
import { useCommunityModal } from "./CommunityModal.hook";
import Loader from "../../common/components/Loader";

interface CommunityModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: CaregiverCommunity | null;
}

export default function CommunityModal({
  post,
  isOpen,
  onClose,
}: CommunityModalProps) {
  const {
    models: { loading, postedAt, author, isDeleted, type },
    operations: { onDelete },
  } = useCommunityModal(onClose, post);

  return (
    <Modal
      title="게시물 조회"
      open={isOpen}
      onClose={onClose}
      width="50%"
      height="80%"
    >
      {loading ? (
        <Loader />
      ) : (
        <Wrapper>
          <Author>
            <UserAvatar src={author?.avatar?.uri} size={70} />
            <AuthorInfoWrapper>
              <Name>
                {author?.name} ({type} - 답글 {post?.replyCount}개)
              </Name>
              {isDeleted ? (
                <span>삭제 ({beautifyDate(post?.deletedAt, true)})</span>
              ) : (
                <span>{postedAt}</span>
              )}
            </AuthorInfoWrapper>
          </Author>
          <Body>{post?.body}</Body>
          <Images>
            {post?.media?.map((image, index) => (
              <Image key={image?.id || index} src={image?.uri} alt="이미지" />
            ))}
          </Images>
          <ButtonWrapper>
            <BasicButton
              text="삭제"
              width="30%"
              backgroundColor="red"
              hoverBackgroundColor="lightRed"
              onClick={onDelete}
            />
          </ButtonWrapper>
        </Wrapper>
      )}
    </Modal>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: theme.spacing.small,
  overflow: "hidden",
  flexGrow: 1,
}));

const Author = styled.div(() => ({
  display: "flex",
  gap: 10,
  alignItems: "center",
}));

const AuthorInfoWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Name = styled.p(() => ({
  margin: 0,
  fontWeight: "bold",
}));

const Body = styled.p(() => ({
  padding: "20px 0",
  margin: 0,
  fontSize: 20,
}));

const Images = styled.div(() => ({
  display: "flex",
  gap: 10,
  overflowX: "auto",
  width: "100%",
  padding: "10px 0",
}));

const Image = styled.img(({ theme }) => ({
  objectFit: "contain",
  width: 200,
  backgroundColor: theme.color.black,
  aspectRatio: 1 / 1,
}));

const ButtonWrapper = styled.div(() => ({
  marginTop: "auto",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));
