import styled from "styled-components";

interface EmptyMessageProps {
  message?: string;
}

export default function EmptyMessage({ message = "결과 없음" }: EmptyMessageProps) {
  return <Message>{message}</Message>;
}

const Message = styled.p(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  margin: 0,
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "semibold",
  fontSize: 20,
  color: theme.color.darkGray,
  border: `1px solid ${theme.color.gray}`,
}));
