import styled from "styled-components";
import useCaregiverInfoItem from "./CaregiverInfoItem.hook";
import DeleteInfoModal from "./DeleteInfoModal";
import { CaregiverInfo } from "../../../lib/apollo/graphql/generated";
import { beautifyDate } from "../../../utilities/format";
import HTMLReactParser from "html-react-parser";

interface CaregiverInfoItemProps {
  info: CaregiverInfo;
  onInfoSelect: (id: string) => void;
}

interface DeleteButtonProps {
  $show: boolean;
}

function CaregiverInfoItem({ info, onInfoSelect }: CaregiverInfoItemProps) {
  const {
    models: {
      isHovered,
      isDeleting,
      id,
      updatedAt,
      deletedAt,
      replyCount,
      body,
      cities,
      thumbnailURI,
    },
    operations: {
      openDeleteModal,
      closeDeleteModal,
      showDeleteButton,
      hideDeleteButton,
    },
  } = useCaregiverInfoItem(info);

  return (
    <>
      <DeleteInfoModal
        isOpen={isDeleting}
        onClose={closeDeleteModal}
        infoId={id}
      />
      <Wrapper
        onMouseOver={showDeleteButton}
        onMouseOut={hideDeleteButton}
        onClick={() => onInfoSelect(info?.id)}
      >
        <DeleteButton
          className="bi bi-trash"
          $show={isHovered}
          onClick={openDeleteModal}
        />
        <Thumbnail src={thumbnailURI} alt="info media" />
        <TextWrapper>
          <Date>
            수정: {beautifyDate(updatedAt, true)}{" "}
            <span>답글: {replyCount}개</span>
          </Date>
          {deletedAt && <p>삭제일: {beautifyDate(deletedAt, true)}</p>}
          <Locations>
            지역:{" "}
            {cities?.map((city, index) => (
              <span key={index}>
                {city?.name}
                {index + 1 === cities.length ? "" : ", "}
              </span>
            ))}
          </Locations>
          <Body>{HTMLReactParser(body)}</Body>
        </TextWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  boxShadow: `0 0 0 1px ${theme.color.gray}`,
  borderRadius: 5,
  height: 350,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing.small,
  position: "relative",
  cursor: "pointer",
  transition: "0.1s ease-in",

  "&:hover": {
    boxShadow: `0 0 0 1px ${theme.color.darkGray}`,
  },
}));

const DeleteButton = styled.i<DeleteButtonProps>(({ theme, $show }) => ({
  color: theme.color.gray,
  transition: "0.1s ease-in",
  cursor: "pointer",
  fontSize: 18,
  position: "absolute",
  bottom: 10,
  right: 10,
  opacity: $show ? 1 : 0,

  "&:hover": {
    color: theme.color.error,
  },
}));

const Thumbnail = styled.img(() => ({
  width: "100%",
  height: "50%",
  objectFit: "cover",
}));

const TextWrapper = styled.div(() => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: 3,
}));

const Date = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 14,
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
}));

const Locations = styled.p(({ theme }) => ({
  fontWeight: "bold",
  color: theme.color.darkGray,
  margin: 0,
  fontSize: 15,
  marginBottom: 10,
  wrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const Body = styled.p(() => ({
  height: "40%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
}));

export default CaregiverInfoItem;
