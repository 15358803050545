import styled from "styled-components";

interface TableHeaderProps {
  tabs: Array<{
    label: string;
    width?: React.CSSProperties["width"];
  }>;
}

interface HeaderTabProps {
  $width: React.CSSProperties["width"];
}

export default function TableHeader({ tabs }: TableHeaderProps) {
  return (
    <Header>
      {tabs.map((tab, index) => (
        <HeaderTab key={tab.label + index} $width={tab.width || "100%"}>
          {tab.label}
        </HeaderTab>
      ))}
    </Header>
  );
}

const Header = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  backgroundColor: theme.color.darkNavy,
  padding: "10px 0 10px 0",
  border: `1px solid ${theme.color.gray}`,
  borderBottom: 0,
  borderRadius: "10px 10px 0 0",
  marginTop: 10,
}));

const HeaderTab = styled.p<HeaderTabProps>(({ theme, $width }) => ({
  width: "100%",
  color: theme.color.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  margin: 0,
  fontSize: 14,
}));
