import { useParams } from "react-router-dom";

export const useRecordDetailContainer = () => {
  const params = useParams();

  return {
    models: {
      recordId: params.recordId,
    },
  };
};
