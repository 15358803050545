import { useState } from "react";

interface ModalActions {
  open: () => void;
  close: () => void;
}

export const useModal = (): [boolean, ModalActions] => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return [
    isOpen,
    {
      open: () => openModal(),
      close: () => closeModal(),
    },
  ];
};
