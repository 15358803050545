import styled from "styled-components";

interface ListPageTopSectionProps {
  children: React.ReactNode;
}

export default function ListPageTopSection({ children }: ListPageTopSectionProps) {
  return <TopSection>{children}</TopSection>;
}

const TopSection = styled.div(() => ({
  display: "flex",
  gap: 10,
  alignItems: "center",
}));
