import styled from "styled-components";
import { ModifiedChat } from "response";
import ShortGridItem from "../../common/components/ShortGridItem";
import { beautifyDate } from "../../../utilities/format";
import LabeledInfo from "../../common/components/LabeledInfo";

interface ChatItemProps {
  chat: ModifiedChat;
}

function ChatItem({ chat }: ChatItemProps) {
  return (
    <ShortGridItem>
      <Wrapper>
        <LeftSide>
          <MessageCount>채팅: {chat?.messageCount}회</MessageCount>
          <LastChatDate>
            마지막 채팅:{beautifyDate(chat?.updatedAt, true)}
          </LastChatDate>
        </LeftSide>
        <RightSide>
          <LabeledInfo label="수급자" info={chat?.receiver?.name} />
        </RightSide>
      </Wrapper>
    </ShortGridItem>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
}));

const LeftSide = styled.div(({ theme }) => ({
  flex: 1,
}));

const RightSide = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

const MessageCount = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.darkGray,
  fontSize: 18,
  fontWeight: "bold",
}));

const LastChatDate = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.gray,
  fontSize: 12,
}));

export default ChatItem;
