import styled from "styled-components";
import UserAvatar from "../../common/components/UserAvatar";
import UpdateUserButton from "./UpdateUserButton";
import { UserType } from "global";
import Loader from "../../common/components/Loader";
import DeleteUserButton from "./DeleteUserButton";

interface ProfileSectionProps {
  name: string;
  id: string;
  src?: string;
  alt?: string;
  children?: React.ReactNode;
  type: UserType;
  isDeletedUser?: boolean;
  isLoading?: boolean;
}

export default function ProfileSection({
  src,
  alt,
  type,
  name,
  id,
  children,
  isDeletedUser = false,
  isLoading = false,
}: ProfileSectionProps) {
  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <AvatarWrapper>
        <UserAvatar src={src} alt={alt} size={100} />
        <Name>{name}</Name>
        <ID>{id}</ID>
      </AvatarWrapper>

      <InfoWrapper>{children}</InfoWrapper>
      <ButtonsWrapper>
        <UpdateUserButton userId={id} type={type} isDisabled={isDeletedUser} />
        {type !== "receivers" && (
          <DeleteUserButton
            userId={id}
            type={type}
            isDisabled={isDeletedUser}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section(({ theme }) => ({
  border: `1px solid ${theme.color.darkGray}`,
  height: "100%",
  width: "25%",
  padding: theme.spacing.middle,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  fontSize: 14,
  borderRadius: 10,
}));

const AvatarWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
}));

const ID = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: 12,
  color: theme.color.darkGray,
}));

const Name = styled.p(() => ({
  margin: 0,
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: 10,
}));

const InfoWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginTop: 20,
}));

const ButtonsWrapper = styled.div(() => ({
  display: "flex",
  gap: 5,
  marginTop: "auto",
}));
