import { useState } from "react";
import { useGetWorkHoursAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { useInView } from "react-intersection-observer";

export const useWorkHistoryBox = (caregiverId: string) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetWorkHoursAsAdminQuery({
    variables: {
      caregiverId: caregiverId,
      receiverId: null,
      first: 20,
    },
  });

  const histories = data?.getWorkHoursAsAdmin.edges;
  const pageInfo = data?.getWorkHoursAsAdmin.pageInfo;

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getWorkHoursAsAdmin.pageInfo;

    if (inView && !isFetchingMore && pageInfo?.hasNextPage) {
      setIsFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: pageInfo?.endCursor,
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      histories,
      pageInfo,
      loading,
      isFetchingMore,
    },
  };
};
