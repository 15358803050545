import styled from "styled-components";
import { Gender } from "../../../lib/apollo/graphql/generated";

interface GenderSelectorProps {
  gender: Gender | null;
  onChange: (newGender: Gender | null) => void;
  isNullable?: boolean;
}

export default function GenderSelector({
  gender,
  onChange,
  isNullable = true,
}: GenderSelectorProps) {
  const onGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (isNullable && !value) {
      onChange(null);
    }

    const gender = value === "male" ? Gender.Male : Gender.Female;

    onChange(gender);
  };

  return (
    <Select onChange={onGenderChange}>
      {isNullable && (
        <option value="" selected={!gender}>
          전체 성별
        </option>
      )}
      <option value="male" selected={gender === Gender.Male}>
        남성
      </option>
      <option value="female" selected={gender === Gender.Female}>
        여성
      </option>
    </Select>
  );
}

const Select = styled.select(({ theme }) => ({
  height: "100%",
  minWidth: 100,
  appearance: "none",
  position: "relative",
  padding: 5,
  border: `1px solid ${theme.color.darkGray}`,
  borderRadius: 5,
  background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path fill="${encodeURIComponent(
    theme.color.darkGray
  )}" d="M0 0h10L5 5z"/></svg>') no-repeat right 10px center`,
  backgroundColor: "white",
  backgroundSize: "10px 5px",
}));
