import { InMemoryCache } from "@apollo/client";
import customRelayStylePagination from "./customRelayStylePagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAnnouncements: customRelayStylePagination(["onBanner", "active"]),
        getReceiversAsAdmin: customRelayStylePagination(["first"]),
        getCaregiversAsAdmin: customRelayStylePagination(["gender"]),
        getGuardiansAsAdmin: customRelayStylePagination(["first"]),
        getReceiverRecordsAsAdmin: customRelayStylePagination(["receiverId"]),
        getCaergiverInfos: customRelayStylePagination(["cityCodes"]),
        getCaregiverCommunitiesByAuthorAsAdmin: customRelayStylePagination(["caregiverId"]),
        getCaregiverCommunityRepliesByCommunityAsAdmin: customRelayStylePagination(["communityId"]),
        getUserPointTransactionsAsAdmin: customRelayStylePagination(["caregiverId", "guardianId"]),
        getWorkHoursAsAdmin: customRelayStylePagination(["recevierId", "caregiverId"]),
        searchCaregiversAsAdmin: customRelayStylePagination(["query", "gender"]),
        searchReceiversAsAdmin: customRelayStylePagination(["query", "gender"]),
        searchGuardiansAsAdmin: customRelayStylePagination(["query", "gender"]),
        getDailyReportsAsAdmin: customRelayStylePagination(["query"]),
      },
    },
  },
});

export const setDefaultCache = (cache: any) => {
  console.info("set default cache:", cache);
};

setDefaultCache(cache);

export default cache;
