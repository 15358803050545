import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetGuardiansAsAdminQuery, useSearchGuardiansAsAdminLazyQuery } from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";
import { UserSearchFilters } from "./CaregiverList.hook";

export const useGuardianList = () => {
  const navigate = useNavigate();

  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [filters, setFilters] = useState<UserSearchFilters>({
    query: "",
    gender: null,
    state: "",
    city: "",
  });

  const { data, loading, fetchMore } = useGetGuardiansAsAdminQuery({
    variables: {
      first: FETCH_SIZE,
    },
  });

  const [searchReceivers, { data: searchData, loading: searchLoading, fetchMore: fetchMoreSearchResults }] =
    useSearchGuardiansAsAdminLazyQuery();

  /**
   * Handler for when user searches for specific set of caregivers
   */
  const onSearch = () => {
    if (!filters.query) {
      onCancelSearch();
      return;
    }

    setIsSearched(true);

    searchReceivers({
      variables: filters,
    });
  };

  const onCancelSearch = () => {
    setIsSearched(false);
    setFilters({
      query: "",
      gender: null,
      state: "",
      city: "",
    });
  };

  /**
   * Handler for when search filter values change
   */
  const onFilterChange = <K extends keyof UserSearchFilters>(key: K, value: UserSearchFilters[K]) => {
    setFilters(previous => ({
      ...previous,
      [key]: value,
    }));
  };

  /**
   * - Handler for when user clicks a user row
   * - Redirects user to user detail page
   */
  const onRouteToUserDetailPage = (guardianId?: string | number) => {
    if (guardianId) {
      navigate(`/detail/guardians/${guardianId}`);
    }
  };

  /**
   * Handler for when intersection observer's inView status changes
   */
  const onViewChange = (inView: boolean) => {
    if (inView) {
      setIsFetchingMore(true);

      if (isSearched) {
        fetchMoreSearchResults({
          variables: {
            first: FETCH_SIZE,
            after: searchData?.searchGuardiansAsAdmin.pageInfo.endCursor,
            ...filters,
          },
        });
      } else {
        fetchMore({
          variables: {
            first: FETCH_SIZE,
            after: data?.getGuardiansAsAdmin.pageInfo.endCursor,
          },
        });
      }

      setIsFetchingMore(false);
    }
  };

  return {
    models: {
      isFetchingMore,
      isSearched,
      loading: searchLoading || loading,
      filters,
      data: isSearched ? searchData?.searchGuardiansAsAdmin.edges : data?.getGuardiansAsAdmin.edges,
      pageInfo: isSearched ? searchData?.searchGuardiansAsAdmin.pageInfo : data?.getGuardiansAsAdmin.pageInfo,
    },
    operations: {
      onRouteToUserDetailPage,
      onSearch,
      onCancelSearch,
      onFilterChange,
      onViewChange,
    },
  };
};

export default useGuardianList;
