import { Dimensions } from "global";
import styled from "styled-components";

interface BaseCardProps extends Dimensions {
  onClick?: () => void;
  children?: React.ReactNode;
}

interface CardProps {
  $isClickable: boolean;
}

export default function BaseCard({ children, onClick }: BaseCardProps) {
  const isClickable = !!onClick;

  return (
    <Wrapper
      $isClickable={isClickable}
      onClick={() => {
        if (isClickable) {
          onClick();
        }
      }}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<CardProps>(({ theme, $isClickable }) => ({
  padding: theme.spacing.small,
  borderRadius: 10,
  border: `1px solid ${theme.color.gray}`,
  display: "flex",
  gap: theme.spacing.small,
  alignItems: "center",
  ...($isClickable && {
    cursor: "pointer",
  }),
}));
