import RecordList from "../components/RecordList";
import SearchBar from "../../common/components/SearchBar";
import { useRecordContainer } from "./RecordContainer.hook";
import SearchTypeToggler from "../components/SearchTypeToggler";
import { DailyReport } from "../../../lib/apollo/graphql/generated";
import PageWrapper from "../../common/components/PageWrapper";
import ListPageTopSection from "../../common/components/ListPageTopSection";

function RecordContainer() {
  const {
    models: { data, loading, pageInfo, filters },
    operations: { onFilterChange, onFetchMore, onSearch },
  } = useRecordContainer();

  return (
    <PageWrapper>
      <ListPageTopSection>
        <SearchTypeToggler selected={filters.searchType} onSelect={type => onFilterChange("searchType", type)} />
        <SearchBar
          value={filters.name}
          placeholder={`${filters.searchType === "CAREGIVER" ? "보호사" : "수급자"} 이름을 입력해 주세요`}
          onChange={value => onFilterChange("name", value)}
          onSearch={onSearch}
        />
      </ListPageTopSection>
      <RecordList
        onFetchMore={onFetchMore}
        hasNextPage={pageInfo?.hasNextPage || false}
        loading={loading}
        records={data as DailyReport[]}
      />
    </PageWrapper>
  );
}

export default RecordContainer;
