import PageWrapper from "../../common/components/PageWrapper";
import ListPageTopSection from "../../common/components/ListPageTopSection";
import GenderSelector from "../components/GenderSelector";
import SearchBar from "../../common/components/SearchBar";
import TableHeader from "../../common/components/TableHeader";
import Table from "../../common/components/Table";
import useGuardianList from "./GuardianList.hook";
import TableRow from "../../common/components/TableRow";
import TableColumn from "../../common/components/TableColumn";
import { Gender } from "../../../lib/apollo/graphql/generated";
import { formatPhoneNumber } from "../../../utilities/format";
import dayjs from "dayjs";
import AddUserButton from "../components/AddUserButton";

export default function GuardianList() {
  const {
    models: { isFetchingMore, isSearched, loading, filters, data, pageInfo },
    operations: { onRouteToUserDetailPage, onSearch, onCancelSearch, onFilterChange, onViewChange },
  } = useGuardianList();

  return (
    <PageWrapper>
      <ListPageTopSection>
        <GenderSelector gender={filters.gender} onChange={gender => onFilterChange("gender", gender)} />
        <SearchBar
          value={filters.query}
          placeholder="보호자 이름 혹은 전화번호"
          onChange={query => {
            onFilterChange("query", query);
          }}
          onSearch={onSearch}
          onCancel={isSearched ? onCancelSearch : undefined}
        />
        <AddUserButton type="guardians" />
      </ListPageTopSection>
      <TableHeader
        tabs={[
          { label: "이름" },
          { label: "성별" },
          { label: "생년월일" },
          { label: "전화번호" },
          { label: "포인트" },
          { label: "가입날" },
          { label: "삭제일" },
        ]}
      />
      <Table
        isLoading={loading}
        isEmpty={!data?.length}
        emptyMessage="조건에 맞는 보호자가 없습니다."
        infiniteScroll={{
          hasNextPage: pageInfo?.hasNextPage || false,
          isFetchingMore,
          onViewChange,
        }}
      >
        {data?.map(guardian => {
          const isDeleted = !!guardian?.deletedAt;

          return (
            <TableRow onClick={() => onRouteToUserDetailPage(guardian?.id)}>
              <TableColumn>{guardian?.name}</TableColumn>
              <TableColumn>{guardian?.gender === Gender.Male ? "남" : "여"}</TableColumn>
              <TableColumn>{dayjs(guardian?.dateOfBirth).format("YYYY년 MM월 DD일")}</TableColumn>
              <TableColumn>{!isDeleted && formatPhoneNumber(guardian?.phone)}</TableColumn>
              <TableColumn>{guardian?.point} P</TableColumn>
              <TableColumn>{dayjs(guardian?.createdAt).format("YY.MM.DD HH:MM")}</TableColumn>
              <TableColumn>{isDeleted && dayjs(guardian?.deletedAt).format("YY.MM.DD HH:MM")}</TableColumn>
            </TableRow>
          );
        })}
      </Table>
    </PageWrapper>
  );
}
