import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useRecordList = (onFetchMore: () => void, hasNextPage: boolean) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const navigate = useNavigate();

  const moveToDetailPage = (recordId?: string) => {
    if (recordId) {
      navigate(`/detail/record/${recordId}`);
    }
  };

  const onViewChange = (inView: boolean) => {
    if (isFetchingMore || !hasNextPage || !inView) {
      return;
    }

    setIsFetchingMore(true);

    onFetchMore();
    setIsFetchingMore(false);
  };

  return {
    models: {
      isFetchingMore,
    },
    operations: {
      moveToDetailPage,
      onViewChange,
    },
  };
};
