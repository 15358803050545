import { UserType } from "global";
import { useState } from "react";

export const USERTYPE_TO_KOREAN: {
  [key in UserType]: string;
} = {
  caregivers: "보호사",
  guardians: "보호자",
  receivers: "수급자",
};

export const useAddUserButton = (type: UserType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openAddUserModal = () => {
    setIsModalOpen(true);
  };

  const closeAddUserModal = () => {
    setIsModalOpen(false);
  };

  return {
    models: {
      koreanType: USERTYPE_TO_KOREAN[type],
      isModalOpen,
    },
    operations: {
      closeAddUserModal,
      openAddUserModal,
    },
  };
};
