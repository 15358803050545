import {
  GetDailyReportAsAdminDocument,
  GetDailyReportsAsAdminDocument,
  useDeleteDailyReportAsAdminMutation,
  useGetDailyReportAsAdminQuery,
} from "../../../lib/apollo/graphql/generated";

export const useRecordDetail = (recordId?: string) => {
  const { data, loading } = useGetDailyReportAsAdminQuery({
    variables: {
      dailyReportId: recordId,
    },
    skip: !recordId,
  });

  const [deleteRecord, { loading: deleteLoading }] = useDeleteDailyReportAsAdminMutation({
    onCompleted: () => {
      alert("삭제되었습니다.");
    },
    refetchQueries: () => [
      {
        query: GetDailyReportAsAdminDocument,
        variables: {
          dailyReportId: recordId,
        },
      },
      {
        query: GetDailyReportsAsAdminDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  const onDelete = () => {
    if (!recordId || !!data?.getDailyReportAsAdmin.deletedAt) {
      return;
    }

    if (!window.confirm("삭제한 돌봄기록은 되돌릴 수 없습니다. 정말 삭제하시겠습니까?")) {
      return;
    }

    deleteRecord({
      variables: {
        dailyReportId: recordId,
      },
    });
  };

  return {
    models: {
      data: data?.getDailyReportAsAdmin,
      loading,
      deleteLoading,
      isDeleted: !!data?.getDailyReportAsAdmin.deletedAt,
    },
    operations: {
      onDelete,
    },
  };
};
