import styled from "styled-components";
import { useUserDetail } from "../../../hooks/useUserDetail";
import LeftLabel from "../../common/components/LeftLabel";
import { formatPhoneNumber, formatDate } from "../../../utilities/format";
import ProfileSection from "./ProfileSection";
import RelatedUsersBox from "./RelatedUsersBox";
import { Gender, Receiver } from "../../../lib/apollo/graphql/generated";
import PointBox from "./PointBox";
import WorkHistoryBox from "./WorkHistoryBox";
import CommunityBox from "./CommunityBox";

export default function CaregiverDetail() {
  const {
    models: { data, loading, isDeletedUser },
    operations: { moveToDetailPage },
  } = useUserDetail("caregivers");

  return (
    <>
      <ProfileSection
        name={data?.name}
        id={data?.id}
        src={data?.avatar?.uri}
        type={"caregivers"}
        isLoading={loading}
        isDeletedUser={isDeletedUser}
      >
        <LeftLabel label="이름" info={data?.name} />
        <LeftLabel label="닉네임" info={data?.username} />
        <LeftLabel label="수급자" info={`${data?.receivers?.length || 0}명`} />
        <LeftLabel
          label="전화번호"
          info={isDeletedUser ? "-" : formatPhoneNumber(data?.phone)}
        />
        <LeftLabel
          label="성별"
          info={data?.gender === Gender.Male ? "남성" : "여성"}
        />
        <LeftLabel label="생년월일" info={formatDate(data?.dateOfBirth)} />
        <LeftLabel label="포인트" info={`${data?.point}P`} />
        <LeftLabel label="자격번호" info={data?.certificateNumber} />
        <LeftLabel
          label="가입일"
          info={formatDate(data?.createdAt, "YYYY.MM.DD HH:MM")}
        />
        <LeftLabel
          label="탈퇴일"
          info={formatDate(data?.deletedAt, "YYYY.MM.DD HH:MM")}
        />
      </ProfileSection>

      <DetailSection>
        <RelatedUsersBox
          type={"receivers"}
          users={data?.receivers as Receiver[]}
          onUserClick={(receiverId) =>
            moveToDetailPage(receiverId, "receivers")
          }
        />
        <CommunityBox caregiverId={data?.id} />
        <WorkHistoryBox caregiverId={data?.id} />
        <PointBox type={"caregivers"} />
      </DetailSection>
    </>
  );
}

const DetailSection = styled.section(({ theme }) => ({
  marginLeft: theme.spacing.small,
  height: "100%",
  flexGrow: 1,
  overflowY: "scroll",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  gap: theme.spacing.small,
}));
