import { UserType } from "global";
import BaseBox from "./BaseBox";
import { usePointBox } from "./PointBox.hook";
import BaseCard from "./BaseCard";
import { PointTransactionType } from "../../../lib/apollo/graphql/generated";
import styled from "styled-components";
import { formatDate, formatNumber } from "../../../utilities/format";
import UserAvatar from "../../common/components/UserAvatar";
import Loader from "../../common/components/Loader";
import FetchMore from "../../common/components/FetchMore";

interface PointBoxProps {
  type: UserType;
}

interface TransactionAmountProps {
  $isProfit: boolean;
}

export default function PointBox({ type }: PointBoxProps) {
  const {
    refs: { fetchMoreRef },
    models: { data, loading, pageInfo, isFetchingMore },
  } = usePointBox(type);

  return (
    <BaseBox title="포인트 내역" isLoading={loading} isEmpty={!data?.length}>
      {data?.map((transaction) => {
        /**
         * True if the user gained points through the transaction
         */
        const isProfit = transaction?.type === PointTransactionType.Addition;
        /**
         * True if the user purchased it himself
         */
        const isPurchase = !!transaction?.purchase;
        /**
         * True if user transaction has been cancelled
         */
        const isCancelled = isPurchase
          ? !!transaction.purchase?.cancelledAt
          : !!transaction?.purchase?.cancelledAt;
        /**
         * The user who made the purchase
         * Null if the transaction is not a purchase
         */
        const purchaser = isPurchase
          ? type === "caregivers"
            ? transaction.purchase?.caregiver
            : transaction.purchase?.guardian
          : null;
        /**
         * The person on the other side of the transaction
         */
        const counterparty =
          type === "caregivers"
            ? transaction?.serviceRequest?.guardian
            : transaction?.serviceRequest?.caregiver;

        return (
          <BaseCard key={transaction?.id}>
            <Counterparty>
              <UserAvatar
                size={70}
                src={
                  isPurchase
                    ? purchaser?.avatar?.uri
                    : counterparty?.avatar?.uri
                }
              />
              <TextWrapper>
                <Name>{isPurchase ? purchaser?.name : counterparty?.name}</Name>
                <Message>
                  {isPurchase
                    ? "(포인트 구매)"
                    : transaction?.serviceRequest?.message || "-"}
                </Message>
              </TextWrapper>
            </Counterparty>
            <TransactionWrapper>
              <TransactionAmount $isProfit={isProfit}>
                {isProfit ? "+" : "-"}
                {formatNumber(transaction?.amount.toString())}P
              </TransactionAmount>
              <Status>
                {isCancelled
                  ? `취소 ${formatDate(
                      transaction?.purchase?.cancelledAt,
                      "YY.MM.DD HH:MM"
                    )}`
                  : formatDate(transaction?.updatedAt, "YY.MM.DD HH:MM")}
              </Status>
            </TransactionWrapper>
          </BaseCard>
        );
      })}
      {isFetchingMore && <Loader />}
      {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
    </BaseBox>
  );
}

const Counterparty = styled.div(() => ({
  display: "flex",
  gap: 10,
  maxWidth: "80%",
}));

const TextWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Name = styled.p(() => ({
  fontWeight: "bold",
  margin: 0,
}));

const Message = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.darkGray,
}));

const TransactionWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
  marginLeft: "auto",
}));

const Status = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: 14,
  color: theme.color.darkGray,
  fontWeight: "light",
}));

const TransactionAmount = styled.div<TransactionAmountProps>(
  ({ theme, $isProfit }) => ({
    padding: theme.spacing.small,
    fontSize: "20px",
    fontWeight: "bold",
    color: $isProfit ? "green" : theme.color.error,
  })
);
