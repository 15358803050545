import PageWrapper from "../../common/components/PageWrapper";
import { UserType } from "global";
import CaregiverDetail from "../components/CaregiverDetail";
import ReceiverDetail from "../components/ReceiverDetail";
import GuardianDetail from "../components/GuardianDetail";

interface UserDetailContainerProps {
  type: UserType;
}

export default function UserDetailContainer({
  type,
}: UserDetailContainerProps) {
  return (
    <PageWrapper isColumnFlex={false}>
      {type === "receivers" && <ReceiverDetail />}
      {type === "guardians" && <GuardianDetail />}
      {type === "caregivers" && <CaregiverDetail />}
    </PageWrapper>
  );
}
