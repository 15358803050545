import styled from "styled-components";
import { Service } from "../../../lib/apollo/graphql/generated";
import { beautifyDate } from "../../../utilities/format";
import DeleteServiceModal from "./DeleteServiceModal";
import useServiceItem from "./ServiceItem.hook";
import DeleteIcon from "../../common/components/DeleteIcon";
import ActiveStatusIcon from "../../common/components/ActiveStatusIcon";
import ShortGridItem from "../../common/components/ShortGridItem";

interface ServiceItemProps {
  service: Service;
  onClick: (id: string | null) => void;
}

function ServiceItem({ service, onClick }: ServiceItemProps) {
  const {
    models: { isDeleting, isHovered },
    operations: { closeDeleteModal, openDeleteModal, onHover, onHoverExit },
  } = useServiceItem();

  return (
    <>
      <DeleteServiceModal
        isOpen={isDeleting}
        onClose={closeDeleteModal}
        service={service}
      />
      <ShortGridItem
        onClick={() => onClick(service.id)}
        onMouseOver={onHover}
        onMouseOut={onHoverExit}
      >
        <ServiceName>
          <span>{service.name}</span>
          <ActiveStatusIcon isActive={service.active} />
          <span>{service.price}P</span>
        </ServiceName>
        <ServiceSummary>{service.summary}</ServiceSummary>
        <LastUpdate>
          마지막 수정: {beautifyDate(service.updatedAt, true)}
        </LastUpdate>
        <DeleteIcon onClick={openDeleteModal} isDisplayed={isHovered} />
      </ShortGridItem>
    </>
  );
}

const ServiceName = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
  display: "flex",
  alignItems: "center",
  gap: 10,

  "&>span:last-of-type": {
    color: theme.color.navy,
    marginLeft: "auto",
  },
}));

const ServiceSummary = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 14,
  marginBottom: 5,
}));

const LastUpdate = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.gray,
  fontWeight: "normal",
  fontSize: 12,
}));

export default ServiceItem;
