import styled from "styled-components";
import useAnnouncementContainer from "./AnnouncementContainer.hook";
import FetchMore from "../../common/components/FetchMore";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";
import AnnouncementCard from "../components/AnnouncementCard";
import UpdateAnnouncementModal from "../components/UpdateAnnouncementModal";
import TwoButtons from "../../common/components/TwoButtons";

function AnnouncementContainer() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data, open },
    operations: { onRefetchClick, onRegisterClick, onClose },
  } = useAnnouncementContainer();

  if (loading) return <Loader />;

  return (
    <>
      <UpdateAnnouncementModal selectedAnnouncementId={null} open={open} onClose={onClose} />
      <PageWrapper isColumnFlex>
        <PageContents>
          <ButtonWrapper>
            <TwoButtons
              text1="등록"
              onClick1={onRegisterClick}
              background1="navy"
              hoverBackground1="lightNavy"
              text2="새로고침"
              onClick2={onRefetchClick}
              background2="navy"
              hoverBackground2="lightNavy"
              wrapperWidth="fit-content"
            />
          </ButtonWrapper>
          <InfoItemWrapper>
            {data?.map(announcement => (
              <AnnouncementCard key={announcement?.id} announcement={announcement!} />
            ))}
            <FetchMore fetchMoreRef={fetchMoreRef} />
          </InfoItemWrapper>
        </PageContents>
      </PageWrapper>
    </>
  );
}

const PageContents = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: theme.color.white,
  borderRadius: 10,
  padding: theme.spacing.regular,
}));

const ButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

const InfoItemWrapper = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(20%, 1fr))",
  gridGap: 20,
  width: "100%",
  padding: theme.spacing.small,
  backgroundColor: theme.color.white,
  overflow: "auto",
  height: "100%",
}));

export default AnnouncementContainer;
