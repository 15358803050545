import styled from "styled-components";

interface SearchBarProps {
  value: string;
  onChange: (newValue: string) => void;
  onSearch: () => void;
  onCancel?: () => void;
  placeholder?: string;
}

export default function SearchBar({
  value,
  onChange,
  onSearch,
  onCancel,
  placeholder = "검색어를 입력해 주세요",
}: SearchBarProps) {
  return (
    <Wrapper>
      <Bar
        onSubmit={event => {
          event.preventDefault();
          onSearch();
        }}
      >
        <Input value={value} onChange={event => onChange(event.target.value)} placeholder={placeholder} />
        <MagnifyingGlassIcon className="bi bi-search" onClick={onSearch} />
      </Bar>
      {!!onCancel && <CancelButton onClick={onCancel}>검색 취소</CancelButton>}
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  gap: 10,
  alignItems: "center",
}));

const Bar = styled.form(({ theme }) => ({
  borderRadius: 5,
  width: 400,
  border: `1px solid ${theme.color.darkGray}`,
  padding: "5px 10px",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  gap: 10,
  transition: "border-color 0.3 ease-in",

  "&:focus-within": {
    borderColor: "blue",
  },
}));

const Input = styled.input(() => ({
  flexGrow: 1,
}));

const MagnifyingGlassIcon = styled.i(() => ({
  fontSize: 20,
  cursor: "pointer",
}));

const CancelButton = styled.span(({ theme }) => ({
  color: theme.color.darkGray,
  cursor: "pointer",
  transition: "0.2s ease-in",
  fontWeight: "bold",

  "&:hover": {
    color: theme.color.error,
    textDecoration: "underline",
  },
}));
