export const COMPRESSION_OPTION = {
  maxSizeMB: 0.3,
};

export const UserType_KR = {
  caregivers: "보호사",
  guardians: "보호자",
  receivers: "수급자",
  deleted: "탈퇴 회원",
};

export const KR_EQUIVALENTS = {
  caregivers: "보호사",
  Caregiver: "보호사",
  guardians: "보호자",
  Guardian: "보호자",
  receivers: "수급자",
  Receiver: "수급자",
  deleted: "탈퇴회원",
  id: "아이디",
  name: "이름",
  username: "닉네임",
  email: "이메일",
  phone: "전화번호",
  gender: "성별",
  createdAt: "생성일",
  updatedAt: "업데이트",
  deletedAt: "삭제일",
  lastRecordAt: "최근기록",
  dateOfBirth: "생일",
  MALE: "남성",
  FEMALE: "여성",
  __typename: "구분",
  avatar: "회원사진",
  certificateMedia: "자격증 사진",
  certificateNumber: "자격번호",
  registrationMedia: "등록증 사진",
  registrationNumber: "등록번호",
  address: "주소",
  addressDetail: "상세주소",
  city: "도시",
  state: "도",
  point: "포인트",
  answers: "답변",
  question: "질문",
};

export const POLICY_TYPE = {
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
};

export const POLICY_TYPE_TEXT = {
  TERMS: "이용약관",
  PRIVACY: "개인정보처리방침",
};

export const FETCH_SIZE = 20;

export const WEEKDAYS_KR = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

export const MEDIA_LIMIT = 5;
