import { UserType } from "global";
import { useGetUserPointTransactionsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

export const usePointBox = (type: UserType) => {
  const { userId } = useParams();

  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const {
    data: cData,
    loading: cLoading,
    fetchMore: cFetchMore,
  } = useGetUserPointTransactionsAsAdminQuery({
    variables: {
      caregiverId: userId,
      first: 20,
    },
    skip: type !== "caregivers",
  });

  const {
    data: gData,
    loading: gLoading,
    fetchMore: gFetchMore,
  } = useGetUserPointTransactionsAsAdminQuery({
    variables: {
      guardianId: userId,
      first: 1,
    },
    skip: type !== "guardians",
  });

  const data =
    type === "caregivers"
      ? cData?.getUserPointTransactionsAsAdmin
      : gData?.getUserPointTransactionsAsAdmin;

  const pageInfo = data?.pageInfo;

  const onViewChange = (inView: boolean) => {
    const fetchMore = type === "caregivers" ? cFetchMore : gFetchMore;

    if (inView && !isFetchingMore && pageInfo?.hasNextPage) {
      setIsFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: pageInfo?.endCursor,
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading: type === "caregivers" ? cLoading : gLoading,
      data: data?.edges,
      pageInfo,
      isFetchingMore,
    },
  };
};
