import styled from "styled-components";
import { useRecordDetail } from "./RecordDetail.hook";
import Loader from "../../common/components/Loader";
import ChatBubble from "./ChatBubble";
import LeftLabel from "../../common/components/LeftLabel";
import { formatPhoneNumber } from "../../../utilities/format";
import dayjs from "dayjs";
import Divider from "../../common/components/Divider";
import BasicButton from "../../common/components/BasicButton";

interface RecordDetailProps {
  recordId?: string;
}

export default function RecordDetail({ recordId }: RecordDetailProps) {
  const {
    models: { data, loading, deleteLoading, isDeleted },
    operations: { onDelete },
  } = useRecordDetail(recordId);

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <MockupPhone>
        <ChatList>
          {data?.recordHistories?.map(history => (
            <>
              <ChatBubble isAi message={history?.recordQuestion?.question} />
              <ChatBubble isAi={false} message={history?.answer} />
              <ChatBubble isAi message={history?.answerComment} />
            </>
          ))}
        </ChatList>
      </MockupPhone>

      <MetadataWrapper>
        <Metadata>
          <MetadataTitle>수급자 정보</MetadataTitle>
          <InfoWrapper>
            <LeftLabel label="이름" info={data?.receiver?.name} />
            <LeftLabel label="전화번호" info={formatPhoneNumber(data?.receiver?.phone!)} />
            <LeftLabel label="주소" info={data?.receiver?.address + " " + data?.receiver?.addressDetail} />
            <LeftLabel label="생년월일" info={data?.receiver?.dateOfBirth} />
          </InfoWrapper>
          <Divider />
          <MetadataTitle>보호사 정보</MetadataTitle>
          <InfoWrapper>
            <LeftLabel label="이름" info={data?.caregiver?.name} />
            <LeftLabel label="닉네임" info={data?.caregiver?.username} />
            <LeftLabel label="전화번호" info={formatPhoneNumber(data?.caregiver?.phone!)} />
            <LeftLabel label="생년월일" info={data?.receiver?.dateOfBirth} />
          </InfoWrapper>
          <Divider />
          <MetadataTitle>돌봄기록 정보</MetadataTitle>
          <InfoWrapper>
            <LeftLabel label="기록 ID" info={data?.id} />
            <LeftLabel label="작성일" info={dayjs(data?.createdAt).format("YYYY년 MM월 DD일 HH시 MM분")} />
            <LeftLabel
              label="삭제일"
              info={isDeleted ? `삭제됨 (${dayjs(data?.deletedAt).format("YYYY년 MM월 DD일 HH시 MM분")})` : ""}
            />
            <LeftLabel label="완료여부" info={!!data?.dailyReport ? "완료" : "미완료"} />
          </InfoWrapper>
        </Metadata>

        <DeleteButtonWrapper>
          <BasicButton
            text={isDeleted ? "삭제된 돌봄기록입니다" : "기록 삭제"}
            fontWeight="bold"
            hoverBackgroundColor="lightRed"
            backgroundColor="red"
            width="100%"
            icon={deleteLoading && <Loader />}
            onClick={onDelete}
            disabled={isDeleted}
          />
        </DeleteButtonWrapper>
      </MetadataWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  gap: 20,
}));

const MockupPhone = styled.div(({ theme }) => ({
  width: "30%",
  height: "100%",
  padding: 10,
  paddingBottom: 0,
  borderRadius: 20,
  border: `3px solid ${theme.color.darkGray}`,
  borderTop: `10px solid ${theme.color.darkGray}`,
  borderBottom: `10px solid ${theme.color.darkGray}`,
}));

const ChatList = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflowY: "scroll",
  padding: 20,
  paddingBottom: 30,
  gap: 20,
}));

const MetadataWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "space-between",
  border: `1px solid ${theme.color.darkGray}`,
  paddingBottom: 30,
  padding: "20px 30px",
  overflowY: "auto",
  borderRadius: 10,
}));

const Metadata = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));

const InfoWrapper = styled.div(() => ({
  padding: "0 10px",
  display: "flex",
  flexDirection: "column",
  gap: 1,
}));

const MetadataTitle = styled.p(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  marginTop: 20,

  "&:first-of-type": {
    marginTop: 0,
  },
}));

const DeleteButtonWrapper = styled.div(() => ({
  width: "30%",
  alignSelf: "center",
}));
