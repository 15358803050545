import styled from "styled-components";
import BasicButton from "../../common/components/BasicButton";
import { UserType } from "global";
import { useAddUserButton } from "./AddUserButton.hook";
import AddUserModal from "./UpdateUserModal";

interface AddUserButtonProps {
  type: UserType;
}

export default function AddUserButton({ type }: AddUserButtonProps) {
  const {
    models: { koreanType, isModalOpen },
    operations: { closeAddUserModal, openAddUserModal },
  } = useAddUserButton(type);

  return (
    <>
      <AddUserModal
        isOpen={isModalOpen}
        onClose={closeAddUserModal}
        type={type}
      />
      <Wrapper>
        <BasicButton text={`${koreanType} 등록`} onClick={openAddUserModal} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div(() => ({
  marginLeft: "auto",
}));
