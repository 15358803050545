import styled from "styled-components";

interface ChatBubbleProps {
  isAi: boolean;
  message?: string | null;
}

interface BubbleProps {
  $isAi: boolean;
}

export default function ChatBubble({ isAi, message }: ChatBubbleProps) {
  if (!message) {
    message = "(답변하지 않음)";
  }

  return <Bubble $isAi={isAi}>{message}</Bubble>;
}

const Bubble = styled.div<BubbleProps>(({ $isAi, theme }) => ({
  backgroundColor: $isAi ? theme.color.lightGray : theme.color.darkNavy,
  color: $isAi ? theme.color.black : theme.color.white,
  alignSelf: $isAi ? "flex-start" : "flex-end",
  maxWidth: "70%",
  padding: "10px 20px",
  borderRadius: 10,
}));
