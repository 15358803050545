import {
  CaregiverCommunity,
  CaregiverCommunityType,
  GetCaregiverCommunitiesByAuthorAsAdminDocument,
  useDeleteCaregiverCommunityMutation,
} from "../../../lib/apollo/graphql/generated";
import { beautifyDate } from "../../../utilities/format";

const TYPE_IN_KR = {
  [CaregiverCommunityType.General]: "일상주제",
  [CaregiverCommunityType.Secret]: "대나무숲",
};

const isNotNull = (
  post: CaregiverCommunity | null
): post is CaregiverCommunity => {
  return !!post?.id;
};

export const useCommunityModal = (
  onClose: () => void,
  post: CaregiverCommunity | null
) => {
  if (!isNotNull(post)) {
    throw new Error("게시물 데이터를 읽어오는 데 에러가 발생헀습니다");
  }

  const [deletePost, { loading }] = useDeleteCaregiverCommunityMutation({
    variables: {
      communityId: post.id,
    },
    onCompleted: () => {
      window.alert("삭제되었습니다");
      onClose();
    },
    refetchQueries: () => [
      {
        query: GetCaregiverCommunitiesByAuthorAsAdminDocument,
        variables: {
          caregiverId: post.author.id,
        },
      },
    ],
  });

  const onDelete = () => {
    if (window.confirm("되돌릴 수 없습니다.\n정말 삭제하시겠습니까?")) {
      deletePost();
    }
  };

  const postedAt = beautifyDate(post?.createdAt, true);
  const author = post?.author;
  const isDeleted = !!post?.deletedAt;
  const type = TYPE_IN_KR[post?.type!];

  return {
    models: {
      loading,
      postedAt,
      author,
      isDeleted,
      type,
    },
    operations: {
      onDelete,
    },
  };
};
