import { Dimensions } from "global";
import styled from "styled-components";
import NoResultMessage from "./NoResultMessage";
import Loader from "../../common/components/Loader";

interface BaseBoxProps extends Dimensions {
  title?: string;
  icon?: React.ReactNode;
  isLoading?: boolean;
  isEmpty?: boolean;
  children?: React.ReactNode;
}

interface WrapperProps {
  $width: React.CSSProperties["width"];
  $height: React.CSSProperties["height"];
}

export default function BaseBox({
  width = "100%",
  height = "100%",
  title,
  icon,
  children,
  isLoading = false,
  isEmpty = false,
}: BaseBoxProps) {
  return (
    <Wrapper $width={width} $height={height}>
      {(title || icon) && (
        <Title>
          {title}
          {icon && icon}
        </Title>
      )}
      {isEmpty ? (
        <NoResultMessage />
      ) : isLoading ? (
        <Loader />
      ) : (
        <List>{children}</List>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $width, $height }) => ({
  width: $width,
  height: $height,
  border: `1px solid ${theme.color.darkGray}`,
  borderRadius: 10,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const Title = styled.div(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `10px 20px`,
  borderBottom: `1px solid ${theme.color.darkGray}`,
}));

const List = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  overflowY: "auto",
  padding: `10px 20px`,
}));
