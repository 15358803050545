import styled from "styled-components";
import Loader from "./Loader";
import EmptyMessage from "./EmptyMessage";
import FetchMore from "./FetchMore";
import { useTable } from "./Table.hook";

interface TableProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  infiniteScroll?: {
    isFetchingMore: boolean;
    hasNextPage: boolean;
    onViewChange: (inView: boolean) => void;
  };
}

export default function Table({
  children,
  isLoading = false,
  isEmpty = false,
  emptyMessage,
  infiniteScroll,
}: TableProps) {
  const isInfiniteScroll = !!infiniteScroll;

  const {
    refs: { fetchMoreRef },
  } = useTable(infiniteScroll?.onViewChange);

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (isEmpty) {
    return <EmptyMessage message={emptyMessage} />;
  }

  return (
    <Wrapper>
      {children}
      {isInfiniteScroll && (
        <>
          {infiniteScroll.isFetchingMore && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {infiniteScroll.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflowY: "scroll",
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.gray}`,
  borderTop: 0,
}));

const LoaderWrapper = styled.div(() => ({
  padding: 20,
}));
