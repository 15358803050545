import styled from "styled-components";

const LABEL_COLORS = {
  green: "#c5f1c5de",
  red: "#f1a5a5c5",
  blue: "#afc1e1f8",
  gray: "#cacaca",
};

const TEXT_COLORS = {
  green: "#268126",
  red: "#ba3939",
  blue: "#2856a6",
  gray: "#434343",
};

type LabelColors = "green" | "red" | "gray" | "blue";

interface ColoredLabelProps {
  text?: string;
  backgroundColor?: LabelColors;
  icon?: React.ReactNode;
}

interface WrapperProps {
  $backgroundColor: LabelColors;
}

function ColoredLabel({ text, backgroundColor = "blue", icon }: ColoredLabelProps) {
  return (
    <Wrapper>
      <Label $backgroundColor={backgroundColor}>
        {icon && icon}
        {text && <p>{text}</p>}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
}));

const Label = styled.div<WrapperProps>(({ theme, $backgroundColor }) => ({
  backgroundColor: LABEL_COLORS[$backgroundColor],
  borderRadius: 5,
  padding: "3px 5px",
  color: TEXT_COLORS[$backgroundColor],
  fontWeight: "bold",
  fontSize: 14,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  minWidth: 80,
  justifyContent: "center",
  gap: 5,

  "&>p": {
    margin: 0,
  },
}));

export default ColoredLabel;
