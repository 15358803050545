import { useInView } from "react-intersection-observer";

export const useTable = (onViewChange?: (inView: boolean) => void) => {
  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
  };
};
