import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCaregiverProfileQuery,
  useGetReceiverQuery,
  useGetGuardianProfileQuery,
  Caregiver,
  Receiver,
  Guardian,
} from "../lib/apollo/graphql/generated";
import { UserType } from "global";

type UserData<T extends UserType> = {
  caregivers: Caregiver;
  receivers: Receiver;
  guardians: Guardian;
}[T];

export const useUserDetail = <T extends UserType>(type: T) => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdatingPoint, setIsUpdatingPoint] = useState(false);

  const moveToDetailPage = (id: string, destination: UserType) => {
    navigate(`/detail/${destination}/${id}`);
  };

  const toggleDeleteModal = () => {
    setIsDeleting((previous) => !previous);
  };

  const togglePointModal = () => {
    setIsUpdatingPoint((previous) => !previous);
  };

  const { data: caregiver, loading: cLoading } = useGetCaregiverProfileQuery({
    variables: {
      caregiverId: userId!,
    },
    skip: type !== "caregivers" || !userId,
  });

  const { data: receiver, loading: rLoading } = useGetReceiverQuery({
    variables: {
      receiverId: userId!,
    },
    skip: type !== "receivers" || !userId,
  });

  const { data: guardian, loading: gLoading } = useGetGuardianProfileQuery({
    variables: {
      guardianId: userId!,
    },
    skip: type !== "guardians" || !userId,
  });

  const USER_INFO_BY_TYPE = {
    caregivers: caregiver?.getCaregiverProfile,
    receivers: receiver?.getReceiver,
    guardians: guardian?.getGuardianProfile,
  };

  const data = USER_INFO_BY_TYPE[type] as UserData<T>;

  return {
    models: {
      isUpdatingPoint,
      isDeleting,
      isDeletedUser: !!data?.deletedAt,
      data,
      loading: rLoading || gLoading || cLoading,
    },
    operations: {
      togglePointModal,
      toggleDeleteModal,
      moveToDetailPage,
    },
  };
};
