import styled from "styled-components";
import { useUserDetail } from "../../../hooks/useUserDetail";
import {
  Caregiver,
  Gender,
  Guardian,
} from "../../../lib/apollo/graphql/generated";
import { formatDate, formatPhoneNumber } from "../../../utilities/format";
import LeftLabel from "../../common/components/LeftLabel";
import ProfileSection from "./ProfileSection";
import RelatedUsersBox from "./RelatedUsersBox";

export default function ReceiverDetail() {
  const {
    models: { data, loading, isDeletedUser },
    operations: { moveToDetailPage },
  } = useUserDetail("receivers");

  return (
    <>
      <ProfileSection
        name={`${data?.name} 수급자`}
        id={data?.id}
        type="receivers"
        isLoading={loading}
        isDeletedUser={isDeletedUser}
      >
        <LeftLabel label="이름" info={data?.name} />
        <LeftLabel
          label="성별"
          info={data?.gender === Gender.Male ? "남성" : "여성"}
        />
        <LeftLabel label="주소" info={data?.address} />
        <LeftLabel label="상세주소" info={data?.addressDetail} />
        <LeftLabel label="보호사" info={`${data?.caregivers?.length || 0}명`} />
        <LeftLabel label="보호자" info={`${data?.guardians?.length || 0}명`} />
        <LeftLabel
          label="전화번호"
          info={isDeletedUser ? "-" : formatPhoneNumber(data?.phone)}
        />
        <LeftLabel label="생년월일" info={formatDate(data?.dateOfBirth)} />

        <LeftLabel label="수급자 번호" info={data?.registrationNumber} />
        <LeftLabel
          label="등록일"
          info={formatDate(data?.createdAt, "YYYY.MM.DD HH:MM")}
        />
      </ProfileSection>
      <DetailSection>
        <RelatedUsersBox
          type="caregivers"
          users={
            data?.caregivers?.map(
              (caregiver) => caregiver?.caregiver
            ) as Partial<Caregiver>[]
          }
          onUserClick={(caregiverId) =>
            moveToDetailPage(caregiverId, "caregivers")
          }
        />
        <RelatedUsersBox
          type="guardians"
          users={
            data?.guardians?.map(
              (guardian) => guardian?.guardian
            ) as Partial<Guardian>[]
          }
          onUserClick={(guardianId) =>
            moveToDetailPage(guardianId, "guardians")
          }
        />
      </DetailSection>
    </>
  );
}

const DetailSection = styled.section(({ theme }) => ({
  marginLeft: theme.spacing.small,
  height: "100%",
  flexGrow: 1,
  overflowY: "scroll",
  display: "flex",
  gap: theme.spacing.small,
}));
