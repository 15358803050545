import dayjs from "dayjs";
import { useRecordList } from "./RecordList.hook";
import { DailyReport } from "../../../lib/apollo/graphql/generated";
import TableHeader from "../../common/components/TableHeader";
import Table from "../../common/components/Table";
import ColoredLabel from "../../common/components/ColoredLabel";
import TableRow from "../../common/components/TableRow";
import TableColumn from "../../common/components/TableColumn";

interface RecordListProps {
  onFetchMore: () => void;
  hasNextPage: boolean;
  loading: boolean;
  records?: DailyReport[];
}

export default function RecordList({ onFetchMore, loading, hasNextPage, records }: RecordListProps) {
  const {
    models: { isFetchingMore },
    operations: { moveToDetailPage, onViewChange },
  } = useRecordList(onFetchMore, hasNextPage);

  return (
    <>
      <TableHeader
        tabs={[
          { label: "완료여부" },
          { label: "수급자" },
          { label: "보호사" },
          { label: "작성 시간" },
          { label: "삭제 시간" },
        ]}
      />
      <Table
        isLoading={loading}
        isEmpty={!records?.length}
        infiniteScroll={{
          isFetchingMore,
          hasNextPage,
          onViewChange,
        }}
      >
        {records?.map(record => (
          <TableRow onClick={() => moveToDetailPage(record?.id)}>
            <TableColumn>
              <ColoredLabel
                text={!!record?.dailyReport ? "완료" : "미완료"}
                backgroundColor={!!record?.dailyReport ? "green" : "red"}
              />
            </TableColumn>
            <TableColumn>{record?.receiver?.name}</TableColumn>
            <TableColumn>{record?.caregiver?.name}</TableColumn>
            <TableColumn>{dayjs(record?.createdAt).format("YY.MM.DD HH:MM")}</TableColumn>
            <TableColumn>{record?.deletedAt ? dayjs(record.deletedAt).format("YY.MM.DD HH:MM") : "-"}</TableColumn>
          </TableRow>
        ))}
      </Table>
    </>
  );
}
