import { useState } from "react";
import { SearchType, useGetDailyReportsAsAdminQuery } from "../../../lib/apollo/graphql/generated";

export type RecordSearchFilter = {
  name: string;
  searchType: SearchType;
};

export const useRecordContainer = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [filters, setFilters] = useState<RecordSearchFilter>({
    name: "",
    searchType: SearchType.Caregiver,
  });

  const onFilterChange = <K extends keyof RecordSearchFilter>(key: K, value: RecordSearchFilter[K]) => {
    setFilters(previous => ({
      ...previous,
      [key]: value,
    }));
  };

  const { data, loading, fetchMore, refetch } = useGetDailyReportsAsAdminQuery({
    variables: {
      first: 20,
      dailyReportSearchQuery: {
        name: filters.name,
        searchType: filters.searchType,
      },
    },
    fetchPolicy: "network-only",
  });

  const onSearch = () => {
    setIsRefetching(true);

    refetch({
      first: 20,
      dailyReportSearchQuery: {
        name: filters.name,
        searchType: filters.searchType,
      },
    }).finally(() => {
      setIsRefetching(false);
    });
  };

  const onFetchMore = () => {
    fetchMore({
      variables: {
        first: 20,
        after: data?.getDailyReportsAsAdmin?.pageInfo.endCursor,
        dailyReportSearchQuery: {
          name: filters.name,
          searchType: filters.searchType,
        },
      },
    });
  };

  return {
    models: {
      filters,
      data: data?.getDailyReportsAsAdmin?.edges || [],
      pageInfo: data?.getDailyReportsAsAdmin?.pageInfo,
      loading: loading || isRefetching,
    },
    operations: {
      onFilterChange,
      onFetchMore,
      onSearch,
    },
  };
};
