import styled from "styled-components";
import { UserType } from "global";
import Modal from "../../common/components/Modal";
import useDeleteUserModal from "./DeleteUserModal.hook";
import Loader from "../../common/components/Loader";
import BasicButton from "../../common/components/BasicButton";

export interface DeleteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  type: UserType;
}

function DeleteUserModal({
  isOpen,
  onClose,
  userId,
  type,
}: DeleteUserModalProps) {
  const {
    models: { loading },
    operations: { onUserDelete },
  } = useDeleteUserModal({ userId, type, onClose });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="회원 삭제"
      width="500px"
      height="500px"
    >
      <Wrapper>
        <Message>
          해당 회원을 삭제하시겠습니까?{"\n"}
          한번 삭제된 회원 정보는 복구할 수 없습니다.
        </Message>
        <ButtonWrapper>
          <BasicButton
            onClick={onClose}
            width="50%"
            text="아니오"
            fontSize="16px"
          />
          <BasicButton
            onClick={onUserDelete}
            icon={loading && <Loader />}
            width="50%"
            backgroundColor="red"
            hoverBackgroundColor="lightRed"
            text="회원 삭제"
            fontSize="16px"
          />
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 10,
}));

const Message = styled.p(() => ({
  whiteSpace: "pre-wrap",
  fontSize: 20,
  fontWeight: "bold",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
}));

const ButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "auto",
  gap: 5,
}));

export default DeleteUserModal;
