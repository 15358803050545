import styled from "styled-components";
import { useRecordDetailContainer } from "./RecordDetailContainer.hook";
import RecordDetail from "../components/RecordDetail";

export default function RecordDetailContainer() {
  const {
    models: { recordId },
  } = useRecordDetailContainer();

  return (
    <Wrapper>
      <RecordDetail recordId={recordId} />
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.middle,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));
