import { UserType } from "global";
import { useState } from "react";
import { USERTYPE_TO_KOREAN } from "./AddUserButton.hook";

const isUserType = (value: string): value is UserType => {
  return ["caregivers", "guardians", "receivers"].includes(value);
};

export const useUpdateUserModal = (type: UserType | null, userId?: string) => {
  const [userType, setUserType] = useState<UserType | null>(type);

  const onToggleType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (!value) {
      setUserType(null);
      return;
    }

    if (isUserType(value)) {
      setUserType(value);
    }
  };

  const isUpdating = !!userId;

  const modalTitle = userType
    ? `${USERTYPE_TO_KOREAN[userType]} ${isUpdating ? "수정" : "추가"}`
    : "";

  return {
    models: {
      userType,
      modalTitle,
      isUpdating,
    },
    operations: {
      onToggleType,
    },
  };
};
