import { UserType } from "global";
import { useModal } from "../../../hooks/useModal";
import BasicButton from "../../common/components/BasicButton";
import UpdateUserModal from "../../users/components/UpdateUserModal";

interface UpdateUserButtonProps {
  type: UserType;
  userId?: string;
  isDisabled: boolean;
}

export default function UpdateUserButton({
  type,
  userId,
  isDisabled,
}: UpdateUserButtonProps) {
  const [isUpdateModalOpen, { open, close }] = useModal();

  return (
    <>
      <UpdateUserModal
        isOpen={isUpdateModalOpen}
        onClose={close}
        type={type}
        userId={userId}
      />
      <BasicButton
        width="100%"
        text="정보 수정"
        fontSize="16px"
        onClick={open}
        disabled={isDisabled}
      />
    </>
  );
}
