import styled from "styled-components";

interface LeftLabelProps {
  label: string;
  info?: React.ReactNode | null;
  children?: React.ReactNode;
}

export default function LeftLabel({ label, info, children }: LeftLabelProps) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {children ? children : <Info>{info || "-"}</Info>}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.xxSmall,
  alignItems: "center",
}));

const Label = styled.p(({ theme }) => ({
  color: theme.color.black1,
  fontWeight: "bold",
  textOverflow: "ellipsis",
  width: "80px",
  margin: 0,
}));

const Info = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  lineClamp: 1,
  textOverflow: "ellipsis",
  margin: 0,
}));
