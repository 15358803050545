import styled from "styled-components";

interface TableRowProps {
  children: React.ReactNode;
  onClick?: () => void;
}

interface RowProps {
  $isClickable: boolean;
}

export default function TableRow({ children, onClick }: TableRowProps) {
  return (
    <Row onClick={onClick} $isClickable={!!onClick}>
      {children}
    </Row>
  );
}

const Row = styled.div<RowProps>(({ theme, $isClickable }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 0px",
  borderBottom: `1px solid ${theme.color.lightGray}`,
  cursor: $isClickable ? "pointer" : "default",
  transition: "background-color 0.3s ease",
  fontSize: 14,

  "&:hover": $isClickable
    ? {
        backgroundColor: theme.color.lightGray,
      }
    : {},
}));
