import styled from "styled-components";

interface UserAvatarProps {
  src?: string;
  alt?: string;
  size?: number;
  isRounded?: boolean;
}

interface AvatarProps {
  $size: number;
  $isRounded: boolean;
}

export default function UserAvatar({
  src = "/images/default_user_image.png",
  alt = "유저 프로필 사진",
  size = 150,
  isRounded = true,
}: UserAvatarProps) {
  return <Avatar $size={size} $isRounded={isRounded} src={src} alt={alt} />;
}

const Avatar = styled.img<AvatarProps>(({ $size, $isRounded }) => ({
  width: $size,
  borderRadius: $isRounded ? $size : 20,
  aspectRatio: 1 / 1,
  objectFit: "cover",
  height: "fit-content",
}));
