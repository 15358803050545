import styled from "styled-components";
import { SearchType } from "../../../lib/apollo/graphql/generated";

interface SearchTypeTogglerProps {
  selected: SearchType;
  onSelect: (type: SearchType) => void;
}

interface TypeItemProps {
  $isSelected: boolean;
}

export default function SearchTypeToggler({ selected, onSelect }: SearchTypeTogglerProps) {
  return (
    <Wrapper>
      <TypeItem $isSelected={selected === "CAREGIVER"} onClick={() => onSelect(SearchType.Caregiver)}>
        보호사
      </TypeItem>
      <TypeItem $isSelected={selected === "RECEIVER"} onClick={() => onSelect(SearchType.Receiver)}>
        수급자
      </TypeItem>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  width: 200,
  height: "100%",
  border: `1px solid ${theme.color.darkGray}`,
  borderRadius: 5,
}));

const TypeItem = styled.p<TypeItemProps>(({ theme, $isSelected }) => ({
  width: "100%",
  margin: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 14,
  fontWeight: "bold",
  cursor: "pointer",
  backgroundColor: $isSelected ? theme.color.darkNavy : "",
  color: $isSelected ? theme.color.white : "",

  "&:first-of-type": {
    borderRight: `1px solid ${theme.color.darkGray}`,
  },
}));
