import {
  GetCaregiverProfileDocument,
  GetGuardianProfileDocument,
  useResignCaregiverAsAdminMutation,
  useResignGuardianAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import { DeleteUserModalProps } from "./DeleteUserModal";

export const useDeleteUserModal = ({
  type,
  onClose,
  userId,
}: Omit<DeleteUserModalProps, "isOpen">) => {
  const [resignCaregiver, { loading: cLoading }] =
    useResignCaregiverAsAdminMutation({
      variables: {
        caregiverId: userId,
      },
      onCompleted: () => {
        window.alert("보호사 회원이 삭제되었습니다.");
        onClose();
      },
      refetchQueries: () => [
        {
          query: GetCaregiverProfileDocument,
          variables: {
            caregiverId: userId,
          },
        },
      ],
    });

  const [resignGuardian, { loading: gLoading }] =
    useResignGuardianAsAdminMutation({
      variables: {
        guardianId: userId,
      },
      onCompleted: () => {
        window.alert("보호자 회원이 삭제되었습니다.");
        onClose();
      },
      refetchQueries: () => [
        {
          query: GetGuardianProfileDocument,
          variables: {
            guardianId: userId,
          },
        },
      ],
    });

  const onUserDelete = () => {
    if (type === "caregivers") {
      resignCaregiver();
    } else {
      resignGuardian();
    }
  };

  return {
    models: {
      loading: gLoading || cLoading,
    },
    operations: {
      onUserDelete,
    },
  };
};

export default useDeleteUserModal;
