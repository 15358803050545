import styled from "styled-components";
import BaseBox from "./BaseBox";
import BaseCard from "./BaseCard";
import { useWorkHistoryBox } from "./WorkHistoryBox.hook";
import {
  beautifyDate,
  getTimeDifferencesInMinutes,
} from "../../../utilities/format";
import Loader from "../../common/components/Loader";
import FetchMore from "../../common/components/FetchMore";

interface WorkHistoryBoxProps {
  caregiverId: string;
}

export default function WorkHistoryBox({ caregiverId }: WorkHistoryBoxProps) {
  const {
    refs: { fetchMoreRef },
    models: { isFetchingMore, histories, loading, pageInfo },
  } = useWorkHistoryBox(caregiverId);

  return (
    <BaseBox
      title="출퇴근 기록"
      isLoading={loading}
      isEmpty={!histories?.length}
    >
      {histories?.map((history) => {
        const start = beautifyDate(history?.createdAt, true);
        const end = beautifyDate(history?.updatedAt, true);
        const total =
          getTimeDifferencesInMinutes(history?.createdAt, history?.updatedAt) +
          "분";

        return (
          <BaseCard>
            <DataWrapper>
              <Name>{history?.receiver.name} 수급자</Name>
              <Timestamp>
                <Icon className="bi bi-clock" /> {start}
              </Timestamp>
              <Timestamp>
                <Icon className="bi bi-clock-fill" /> {end}
              </Timestamp>
            </DataWrapper>
            <TotalWorkTime>{total}</TotalWorkTime>
          </BaseCard>
        );
      })}
      {isFetchingMore && <Loader />}
      {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
    </BaseBox>
  );
}

const Name = styled.p(() => ({
  fontSize: 18,
  fontWeight: "bold",
  margin: "0 0 10px 0",
}));

const TotalWorkTime = styled.p(({ theme }) => ({
  margin: 0,
  marginLeft: "auto",
  fontWeight: "bold",
  fontSize: 24,
  color: theme.color.darkNavy,
}));

const DataWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 1,
  padding: "5px 10px",
}));

const Timestamp = styled.p(({ theme }) => ({
  fontWeight: "normal",
  margin: 0,
  color: theme.color.darkGray,
  display: "flex",
  alignItems: "center",
  gap: 5,
}));

const Icon = styled.i(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 12,
}));
